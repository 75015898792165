'use strict';

$(document).ready(function () {
  $('.check-balance').on('click', function (e) {
    e.preventDefault();
    const $element = $('[data-giftcard-balance-src]');
    const src = $element.attr('data-giftcard-balance-src');
    $('<script>').attr('src', src).appendTo('head');
  });
});
